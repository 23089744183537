<template>
  <div>
    <div v-if="visual_phone == true">
      <van-row type=:flex justify="center">
        <van-col span="4"></van-col>
        <van-col span="16">
          <van-tag type="primary" class="top-tag">使用方法</van-tag>
        </van-col>        
        <van-col span="4"></van-col>
      </van-row>      

      <van-divider dashed />

      <br>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">1.点击本页面【开始取码】按钮</van-tag>           
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="6"></van-col>
        <van-col span="6">
          <van-icon name="down" size="15px"/>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">2.复制手机号到必胜客APP的登录页面</van-tag>           
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      
      <van-row type="flex" class="each-row">
        <van-col span="6"></van-col>
        <van-col span="6">
          <van-icon name="down" size="15px"/>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">3.必胜客APP上点击发送验证码</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="6"></van-col>
        <van-col span="6">
          <van-icon name="down" size="15px"/>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">4.步骤三：在本页停留等待获取验证码</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="6"></van-col>
        <van-col span="6">
          <van-icon name="down" size="15px"/>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">5.获取验证码复制到必胜客APP完成登录</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>


      <van-divider dashed />

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="red-tag">如果长时间没有获取验证码，请重复操作一次。如在APP上发送两次还没收到验证码，联系客服退款</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-divider dashed />

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="blue-tag">复制下面手机号到必胜客APP，必胜客APP上点击发送验证码，在此页面等待30秒将自动获得验证码</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="16" >
          <van-tag type="primary" class="phone-tag"> 手机号： <span> {{ phone }} </span> </van-tag> 
        </van-col>
        <van-col span="5">
          <van-button plain type="primary"
            class="copy-phone-btn iconfont icon-fuzhi"
            size="small"
            :data-clipboard-text="phone"
            @click="copyPhone()"
            >复制手机号</van-button>
        </van-col>          
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="13">
          <van-tag type="primary" class="verify-tag"> 验证码： <span  class="blue-tag-more"> {{ verify }} </span> </van-tag> 
        </van-col>
        <van-col span="3">
          <van-button plain type="primary"
            size="small"
            @click="apiSms()"
            ><span id = "btnApiSms">开始取码</span></van-button>

        </van-col>
        <van-col span="5">
          <div v-if="visual_verify == true">
          <van-button plain type = "primary"
            class="copy-verify-btn iconfont icon-fuzhi"
            size="small"
            :data-clipboard-text="verify"
            @click="copyVerify()"
            >复制验证码</van-button>
          </div>
        </van-col>
      </van-row>

      <van-divider contentPosition="center">注意事项</van-divider>

      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          1.确定填了地址再提交订单，若有运费重启APP尝试。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          2.若重复几次发现还有运费，请申请退款重新拍一个。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          3.账号使用有效期一个小时，请尽快使用。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          4.一个账号只能免一单运费，请勿多拍。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          5.账号的V金禁止使用，谢谢配合！
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      </div>
      <div v-else-if="visual_sayBye == true">
        <van-row type=:flex justify="center">
        <van-col span="4"></van-col>
        <van-col span="16">
          <van-tag type="primary" class="top-tag">缺号了</van-tag>
        </van-col>        
        <van-col span="4"></van-col>
      </van-row>

  <van-divider dashed />

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">非常抱歉影响您的使用，请申请退款</van-tag>           
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

  <van-divider dashed />    

      </div> 
    <div v-else> </div>

    <van-dialog
      v-model="visual_check"
      show-cancel-button
      confirm-button-text="现在使用"
      cancel-button-text="晚些再用"
      :beforeClose="beforeCheckClose"
      >
      <div style="width: 100%; height: 160px; display: flex; flex-direction: column; align-items: center;">
      <span style="width: 100%; line-height: 26px; font-size: 16px; color: #ffffff; text-align: center; background-color: #37AAEA;">确定取号？</span>

      <van-divider dashed />                    
        <div style="display: flex; flex-direction: column; align-items: left;">
            <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">{{ check_val }}</van-tag>
            <van-divider dashed />
            <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">点击确定后取号并开始计时，请尽快使用！</van-tag>
        </div>
      <van-divider dashed />
      </div>
    </van-dialog>

    <van-dialog
      v-model="visual_alert"
      show-cancel-button
      :beforeClose="beforeAlertClose"
      >
      <div style="width: 100%; height: 160px; display: flex; flex-direction: column; align-items: center;">
      <span style="width: 100%; line-height: 26px; font-size: 16px; color: #ffffff; text-align: center; background-color: #37AAEA;">过期取号？</span>

      <van-divider dashed />                    
        <div style="display: flex; flex-direction: column; align-items: left;">
            <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">访问失败？</van-tag>
            <van-divider dashed />
            <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">{{ alert_val }}</van-tag>
        </div>
      <van-divider dashed />
      </div>
    </van-dialog>


    <van-dialog
      v-model="visual_notice"
      :beforeClose="beforeNoticeClose"
      confirm-button-color="#ee0a24"
      :showConfirmButton="false" >
      
      <div style="width: 100%; height: 190px; display: flex; flex-direction: column; align-items: center;">
        <span style="width: 100%; line-height: 26px; font-size: 16px; color: #FFFFFF; text-align: center; background-color: #37AAEA;">重要通知</span>

        <van-divider dashed />                    
          <div style="display: flex; flex-direction: column; align-items: left;">
              <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">{{ notice_val }}</van-tag>
              <van-divider dashed />
              <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">复制手机号成功!</van-tag>
          </div>
        <van-divider dashed />
      </div>

      <div class="dialog-button-bg">
        <div v-if="totalTime > 0" 
          style="margin-top: 10px; width: 50%;margin-bottom: 10px;display: inline-block;height: 34px;vertical-align: middle;line-height: 34px;background: rgb(143, 142, 142);color: white; border-radius: 4px;font-size: 14px;">
            {{ confirmContent }}
        </div>

      <van-button
      v-else
      type="info"
      style=" width: 50%;height: 34px;margin-bottom: 10px; margin-top: 10px; border-radius: 4px; "
      @click="closeClickNotice">关闭</van-button>
      </div>
    </van-dialog>

    <van-dialog
      v-model="visual_failed"
      show-cancel-button
      :beforeClose="beforeFailedClose"
      >
      <div style="width: 100%; height: 160px; display: flex; flex-direction: column; align-items: center;">
      <span style="width: 100%; line-height: 26px; font-size: 16px; color: #ffffff; text-align: center; background-color: #37AAEA;">暂时缺号！</span>

      <van-divider dashed />                    
        <div style="display: flex; flex-direction: column; align-items: left;">
            <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">Sorry 暂时缺号！</van-tag>
            <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">点击确认后，请对订单发起退款!</van-tag>
        </div>
      <van-divider dashed />
      </div>
    </van-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import Clipboard from "clipboard";
import {Button} from 'vant'
import { Col, Row, Tag, Icon, Toast, Divider  } from 'vant';

export default {
  inject:['reload'],
  components: {
    vanButton:Button,
    vanCol:Col,
    vanRow:Row,
    vanTag:Tag,
    vanIcon:Icon,
    vanDivider:Divider 
  },

  data() {
    return {
      token:'',
      phone:'',
      verify:'点击取码 ->',
      visual_phone:false,
      visual_verify:false,
      visual_check:false,
      check_val:'确定要现在使用吗？',
      visual_alert:false,
      alert_val:'请不要访问过期的订单!',
      visual_notice:false,
      visual_failed:false,
      visual_sayBye:false,
      confirmContent: "确认", //倒计时之后按钮提示文字
      totalTime: 10, //倒计时30s
      interval_flag:false,
      interval_wait:6,
      interval_handle:-1,
    };
  },

  computed: {
  },


  mounted(){
  },

  methods: {

    verificationCode() {
      this.confirmContent = this.totalTime + "s后可关闭";
      window.console.log('confirmContent:' + this.confirmContent)
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.confirmContent = this.totalTime + "s后可关闭";
        if (this.totalTime <= 0) {
          clearInterval(clock);
        }
      }, 1000);
    },

    closeClickNotice() {
      this.visual_notice = false;
    },

    copyPhone(){
      let clipboard = new Clipboard('.copy-phone-btn')
      clipboard.on('success',(e)=>{
        window.console.log(e)
        //window.console.log('复制成功.')  
        if ( // 返回判断的值
          (typeof this.notice_val == 'undefined')
                ||
          (this.notice_val == null)
                ||
          (this.notice_val == false)        //类似: !x
                ||
          (this.notice_val.length == 0)
                ||
          (this.notice_val == 0)            // 这里是判断 0，不需要刻意去掉
                ||
          (this.notice_val == "")
                ||
          (this.notice_val.replace(/\s/g,"") == "")
                ||
          (!/[^\s]/.test(this.notice_val))
                ||
          (/^\s*$/.test(this.notice_val))){
          window.console.log('no notice_val at all' )  
          Toast('复制成功')
        }else{
          window.console.log('notice_val.' + this.notice_val)  
          this.visual_notice = true
          this.verificationCode(); //进入当前界面就需要调用倒计时方法
        }
        
        clipboard.destroy()
      })
      clipboard.on('error',(e)=>{
        window.console.log(e)
        //window.console.log('复制失败.')  
        clipboard.destroy()
      })
    },

    copyVerify(){
      let clipboard = new Clipboard('.copy-verify-btn')
      clipboard.on('success',(e)=>{
        window.console.log(e)
        //window.console.log('复制成功.')  
        Toast('复制验证码成功')
        clipboard.destroy()
      })
      clipboard.on('error',(e)=>{
        window.console.log(e)
        //window.console.log('复制失败.')  
        clipboard.destroy()
      })
    },

    dateDigitToString(num) {  
      return num < 10 ? '0' + num : num;  
    }, 

    intervalCall(){

      // var currentDate = new Date(),  
      // year = this.dateDigitToString(currentDate.getFullYear()),  
      // month = this.dateDigitToString(currentDate.getMonth() + 1),//Date.getMonth()的返回值是0-11,所以要+1  
      // date = this.dateDigitToString(currentDate.getDate()),  
      // hour = this.dateDigitToString(currentDate.getHours()),  
      // minute = this.dateDigitToString(currentDate.getMinutes()),  
      // second = this.dateDigitToString(currentDate.getSeconds()),  
      // formattedDateString = year + '年' + month + '月' + date + '日 ' + hour + ':' + minute + ':' + second;  
      // window.console.log( formattedDateString + 'this.interval_wait:' + this.interval_wait);

      //window.console.log('this.interval_flag:' + this.interval_flag);
      //window.console.log('this.interval_wait:' + this.interval_wait);
      if(this.interval_flag){
        let timeoutFlag = 0;
        // 倒数第2秒请求
        if (this.interval_wait == 2){          
            const url = '/apisms/sms-bsk-dd/' + this.token;    
            axios.get(url).then(
                res => {
                  let result = res.data.data
                  let code = result.code;
                  if(code == '0'){
                    let return_verify = result.verify_code
                    window.console.log(return_verify);
                    if(typeof(return_verify)=="undefined" || return_verify == '' || return_verify == null){
                      window.console.log('none return_verify');
                    }else{
                      // 停止定时器
                      this.interval_flag = false;
                      window.console.log('got return_verify');
                      this.verify = return_verify
                      this.visual_verify = true
                    }
                  }else if(code == '2'){
                      timeoutFlag = 1;
                      this.verify='已超时 ...'; 
                      if(this.interval_handle != -1){
                        this.interval_flag = false;
                        clearInterval(this.interval_handle)
                      }
                      window.console.log('timeout');
                  }else{
                      window.console.log('failed');
                  }
                }
            ).catch(
                rsp => {
                  window.console.log(rsp);          
                },
            );            
          }
          if(this.interval_wait < 2){
            this.verify='取码中 ...'
          }
          if(this.interval_wait < 0){
            // 重置6秒一次
            this.interval_wait = 10
          }
          this.interval_wait = this.interval_wait -1
          if(this.interval_wait > 2){
            // 按钮显示倒计时
            this.verify='等待中 . ' + String(this.interval_wait -1) 
          }
          if(timeoutFlag == 1){
            this.interval_flag = false;
            this.verify='已超时 ...'; 
          }
        }
    },

    apiSms(){
      if(this.interval_handle != -1){
        this.interval_flag = false;
        clearInterval(this.interval_handle)
      }
      this.interval_flag = true;
      this.interval_handle = setInterval(() => {
        this.intervalCall()
      }, 1000);
    },
    beforeCheckClose(action, done) {
      if(action === 'confirm') {
          setTimeout(done, 1000)
          this.fetchPhone();
          // setTimeout(done, 1000)
          window.console.log('confrm')
      } else if(action === 'cancel') {
          window.console.log('cancel')
          return done() //关闭
      }
    },
    fetchPhone(){
      const shop = this.$route.params.shop;
      const token = this.$route.params.token;
      window.console.log('fetchPhone');
      if(shop && token){
        this.token = token
        // 发ajax请求，用以获取数据，此处地址意思是查询 github中 vue 星数最高的项目
        const url = 'bskcnt/sms-bsk-dd/' + shop + '/' + token;    
        axios.get(url).then(
            res => {
              let result = res.data.data
              let code = result.code;
              if(code == '0'){
                let sim_code = result.sim_code;
                this.phone = sim_code;
                this.visual_phone = true
                let return_verify = result.verify_code
                window.console.log(return_verify);
                if(typeof(return_verify)=="undefined" || return_verify == '' || return_verify == null){
                  window.console.log('none return_verify');
                }else{
                  window.console.log('got return_verify');
                  this.verify = return_verify
                  this.visual_verify = true
                }
              }else{
                this.visual_phone = false
                this.visual_failed = true
                window.console.log('failed');
              }
            }
        ).catch(
            rsp => {
              window.console.log(rsp);          
            },
        );
      }else{
        this.visual_phone = false
        window.console.log('noe allow');
      }      
    },  
    beforeAlertClose(action, done) {
      if(action === 'confirm') {
          window.console.log('confrm')
          return done() //关闭         
      } else if(action === 'cancel') {
          window.console.log('cancel')
          return done() //关闭
      }
    },
    beforeNoticeClose(action, done) {
      if(action === 'confirm') {
          window.console.log('confrm')
          return done() //关闭         
      } else if(action === 'cancel') {
          window.console.log('cancel')
          return done() //关闭
      }
    },
    beforeFailedClose(action, done) {
      if(action === 'confirm') {
          setTimeout(done, 1000)
          this.cancelToken();
          // setTimeout(done, 1000)
          window.console.log('confrm')
      } else if(action === 'cancel') {
          window.console.log('cancel')
          return done() //关闭
      }
    },
    cancelToken(){
      const shop = this.$route.params.shop;
      const token = this.$route.params.token;
      window.console.log('cancelToken');
      if(shop && token){
        this.token = token
        // 发ajax请求，用以获取数据，此处地址意思是查询 github中 vue 星数最高的项目
        const url = '/tokendel/sms-bsk-dd/' + shop + '/' + token;    
        axios.get(url).then(
            res => {
              let result = res.data.data
              let code = result.code;
              if(code == '0'){
                this.visual_failed = false
                this.visual_sayBye = true
                window.console.log('bye');
              }else{
                this.visual_failed = false
                this.visual_sayBye = true
                window.console.log('bye failed');
              }
            }
        ).catch(
            rsp => {
              window.console.log(rsp);          
            },
        );
      }else{
        this.visual_phone = false
        window.console.log('noe allow');
      }      
    },
    
  },

  created(){

    (function () {
      var viewportTag = null;
      var metaTags = document.getElementsByTagName('meta');
      for (var i = 0; i < metaTags.length; i++) {
        if (metaTags[i].getAttribute('name') === "viewport") {
          viewportTag = metaTags[i];
          break;
        }
      }
      if (!viewportTag) {
        viewportTag = document.createElement("meta");
        viewportTag.setAttribute('name', 'viewport');
      }

      var viewportTagContent = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=0';

      // Detect if iOS device
      if (/(iPhone|iPod|iPad)/i.test(window.navigator.userAgent)) {
        // Get iOS major version
        var iosVersion = parseInt((window.navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)? like Mac OS X/i)[1]);
        // Detect if device is running >iOS 11
        // iOS 11's UIWebView and WKWebView changes the viewport behaviour to render viewport without the status bar. Need to override with "viewport-fit: cover" to include the status bar.
        if (iosVersion >= 11) {
          viewportTagContent += ', viewport-fit=cover';
        }
      }

      // Update viewport tag attribute
      viewportTag.setAttribute('content', viewportTagContent);
    })()

    const shop = this.$route.params.shop;
    const token = this.$route.params.token;
    if(shop && token){
      this.token = token
      // 发ajax请求，用以获取数据，此处地址意思是查询 github中 vue 星数最高的项目
      const url = 'bskcheck/sms-bsk-dd/' + shop + '/' + token;    
      axios.get(url).then(
          res => {
            let result = res.data.data
            let code = result.code;
            let go_fetch = result.go_fetch;
            if(code == '1'){
                this.visual_phone = false
                window.console.log('not again');
                this.alert_val = result.alert_val;
                this.visual_alert = true;
            }else{
              if(go_fetch == '1'){
                this.visual_phone = false
                window.console.log('dialog')
                this.visual_check = true;
                this.check_val = result.check_val;
                this.notice_val = result.notice_val;
              }else{
                let sim_code = result.sim_code;
                this.phone = sim_code;
                this.visual_phone = true
                let return_verify = result.verify_code
                window.console.log(return_verify);
                if(typeof(return_verify)=="undefined" || return_verify == '' || return_verify == null){
                  window.console.log('none return_verify');
                }else{
                  window.console.log('got return_verify');
                  this.verify = return_verify
                  this.visual_verify = true
                }
              }
            }
          }
      ).catch(
          rsp => {
            window.console.log(rsp);          
          },
      );
    }else{
      this.visual_phone = false
      window.console.log('noe allow');
    }
  },
  beforeDestroy() {
    clearInterval(this.interval_handle)
    this.interval_handle = null
  }
};
</script>

<style scoped>
.body {
  font-size: 0.3rem;
}
.each-row{
  font-size: 1px;;
}
.top-tag {
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.sub-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:black;
  background-color:yellow;
}

.red-w-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:crimson;
  background-color:white
}

.red-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:crimson;
  background-color:#DFE0E7;
}

.blue-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:blue;
  background-color:white;
  margin-bottom: 10px;
}

.blue-tag-more {
  font-size: 0.5rem;
  line-height: 0.8rem;
  color:blue;
  background-color:white;
  margin-bottom: 3px;
}

.phone-tag {
  font-size: 0.5rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}

.verify-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}
.note-row{
  font-size: 0.35rem;
  color:gray;
  margin-bottom: 5px;
}
.dialog-button-bg {
  background: white;
  text-align: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>